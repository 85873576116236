<template>
  <section>
    <h1>Error 404</h1>

    {{error}}

    <NuxtLink to="/">Home</NuxtLink>
  </section>
</template>

<script>
  export default {
    props: ['error'],
    layout: 'error' // you can set a custom layout for the error page
  }
</script>

<style scoped>
  section {
    width: 600px;
    margin: 0 auto;
    padding-top: 5rem;
  }

  h1 {
    color: red;
  }
</style>

